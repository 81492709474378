import { useStore } from "@/store"
import { computed } from "vue"

export function useSchoolsUser(schoolsUser) {
  const store = useStore()

  const accessibilityOption = computed(() => {
    return store.getters.accessibilityOptionBySchoolsUserId(schoolsUser?.id)
  })

  const timeAccommodation = computed(() => {
    return store.getters.timeAccommodation(accessibilityOption.value?.time_accommodation_id)
  })

  const school = computed(() => {
    return store.getters.schools.find(s => s.id === schoolsUser.school_id)
  })

  function facade() {
    return {
      ...schoolsUser,
      timeAccommodation: timeAccommodation.value,
      accessibilityOption: accessibilityOption.value,
    }
  }

  return {
    facade,
    accessibilityOption,
    timeAccommodation,
    school,
  }
}
